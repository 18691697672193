import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';

import MainContainer from "../components/containers/MainContainer"
import HeadDataNew from "../data/HeadDataNew"
import HomeHeroMobileVersionUpdateNew from "../components/headers/HomeHeroMobileVersionUpdateNew";
import HomeAboutSectionUpdate from "../components/sections/HomeAboutSectionUpdate"
import HomeProductsSectionNew from "../components/sections/HomeProductsSectionNew"
import CtaSectionNew from "../components/sections/CtaSectionNew"
import HomeUSAMadeSectionNew from "../components/sections/HomeUSAMadeSectionNew"
import HomeIntroSectionNew from '../components/sections/HomeIntroSectionNew'
import FeaturedProductsSection from '../components/sections/FeaturedProductsSection'
import HomeVideoSectionUpdate from "../components/sections/HomeVideoSectionUpdate";
import ProductLandingPageBannerSectionUpdate from "../components/sections/ProductLandingPageBannerSectionUpdate"
import HomeHeroWithImageBackground from "../components/headers/HomeHeroWithImageBackground";

const HomePage = () => {
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      prismicHomePage {
        data {
          meta_title
          meta_description
          make_banner_section_visible_and_remove_featured_section
        }
      }
    }
  `)

  const doc = data.prismicHomePage.data

  const VideoBgUpdateNew = loadable(() =>
    pMinDelay(import('../components/headers/VideoBgUpdateNew'), 3000),{
      fallback:
        <HomeHeroWithImageBackground />
    }
  )

  const Container = 'w-full md:h-64 mx-2 sm:mb-8 text-center px-6 pt-8 md:px-12 pb-6 md:mb-6 border-t-4 border-red-900 md:mx-auto'
  const Title = 'font-display text-2xl text-red-900 mt-4 tracking-wide'
  const positionTitle = 'mb-3 text-gray-800 text-sm'
  const Parag = 'text-xl sm:text-lg text-black font-body italic'

  const HomeTestiSectionUpdate = loadable(() =>
    pMinDelay(import('../components/sections/HomeTestiSectionUpdate'), 3000),{
      fallback:
        <div className='bg-gray-100 py-12 md:py-16'>
        <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto'>
          <h2 className='font-display text-4xl md:text-5xl text-center xl:text-left text-gray-900 mb-6 tracking-wider'>What Customers <span className='text-red-900'>Say</span></h2>
        </div>

        <div className='w-9/12 xl:w-6/12 xl:max-w-screen-xl mx-auto'>
          <div className={Container}>
            <p className={Parag}>"I prepped my doors to the wrong hinge template but they we are able to change their standard prep to match mine. I am very impressed with command access right now. All future transfer hinges my company orders in the future will need to be command access."</p>
            <h2 className={Title}>Casey</h2>
            <p className={positionTitle}>Owner</p>
          </div>
        </div>
      </div>
    }
  )


  return (
    <MainContainer>
      <HeadDataNew 
        title={doc.meta_title}
        description={doc.meta_description}
      />
      
      <HomeHeroMobileVersionUpdateNew />

      <VideoBgUpdateNew />

      <HomeIntroSectionNew />

      {doc.make_banner_section_visible_and_remove_featured_section
        ?
        <ProductLandingPageBannerSectionUpdate />
        :
        <FeaturedProductsSection />
      }

      <HomeProductsSectionNew />

      <HomeVideoSectionUpdate />

      <HomeAboutSectionUpdate />

      <HomeTestiSectionUpdate />

      <CtaSectionNew />

      <HomeUSAMadeSectionNew />
    </MainContainer>
  )
}

export default HomePage
