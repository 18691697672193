import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getSrc } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import loadable from '@loadable/component';
import styled from "styled-components"

const HomeHeroMobileText = loadable(() => import('./HomeHeroMobileText'));

const HomeHeroMobileVersionUpdateNew = () => {
  const data = useStaticQuery(graphql`
    query HomeHeroMobileVersionUpdateNewQuery {      
      imageBg: file(relativePath: { eq: "header/hero-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            formats: [WEBP]
          )
        }
      }
    }
  `)

  const imageBg = getSrc(data.imageBg)

  const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100vh;
    background-image: url(${imageBg}); /* The image used */
    background-color: #000000; /* Used if the image is unavailable */
    /* height: 500px;  You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    @media (min-width: 1280px) {
      display: hidden;
    }
  `

  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={imageBg} />
      </Helmet>

      <div className="block xl:hidden">
        <StyledDiv>
          <HomeHeroMobileText />
        </StyledDiv>          
      </div>
    </>
  )
}

export default HomeHeroMobileVersionUpdateNew