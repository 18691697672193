import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { PrismicRichText } from "@prismicio/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const cardContainer = 'flex flex-col items-center justify-center border border-3 border-gray-300 p-6'

const HomeIntroSectionNew = () => {
  const data = useStaticQuery(graphql`
    query HomeIntroSectionNewQuery {
      prismicHomePage {
        data {
          intro_section_gray_text_title
          intro_section_red_text_title
          intro_section_description {
            richText
          }
          intro_section_file {
            link_type
            url
          }
          intro_section_image {
            gatsbyImageData(width: 1200)
          }
        }
      }
    }
  `)

  const doc = data.prismicHomePage.data

  return (
    <div className='bg-white pt-10 pb-2 border-t border-gray-200'>
      <div className='w-11/12 xl:max-w-screen-xl mb-12 mx-auto'>
        <h2 className='font-display text-5xl md:text-6xl text-gray-900 text-center leading-tight tracking-wide'>{doc.intro_section_gray_text_title} <span className='text-red-900'>{doc.intro_section_red_text_title}</span></h2>

        <PrismicRichText
          field={doc.intro_section_description.richText}
          components={{
            paragraph: ({ children }) => <p className="prismic-about-section-p-class">{children}</p>
          }}
        />
      </div>

      <div className='w-11/12 xl:max-w-screen-xl grid grid-cols-1 md:grid-cols-3 gap-6 mx-auto mb-12'>
        <div className={cardContainer}>
          <h2 className='font-display text-6xl text-red-900 -mb-5 leading-tight tracking-wide'>C.A.T</h2>
          <h2 className='font-display text-4xl text-gray-900 leading-tight tracking-wide'>Commitment</h2>
        </div>

        <div className={cardContainer}>
          <h2 className='font-display text-5xl text-red-900 -mb-5 leading-tight tracking-wide'>No Hassle</h2>
          <h2 className='font-display text-5xl text-gray-900 -mb-5 leading-tight tracking-wide'>3 Year</h2>
          <h2 className='font-display text-5xl text-red-900 leading-tight tracking-wide'>Warranty</h2>
        </div>

        <div className={cardContainer}>
        <h2 className='font-display text-5xl text-red-900 -mb-5 leading-tight tracking-wide'>Innovative</h2>
          <h2 className='font-display text-5xl text-gray-900 -mb-5 leading-tight tracking-wide'>Solutions</h2>
        </div>
      </div>

      <div className='w-11/12 xl:max-w-screen-xl mb-12 mx-auto'>
        <div className='w-full lg:w-8/12 mx-auto'>
          <a href={doc.intro_section_file.url} target='_blank' rel='noopener noreferrer'>
            <GatsbyImage
              image={getImage(doc.intro_section_image)}
              alt="New East Coast Warehouse Graphic Image"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default HomeIntroSectionNew