import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { PrismicRichText } from "@prismicio/react"
import styled from 'styled-components'
import loadable from '@loadable/component'

const YoutubePlayerLite = loadable(() => import('../common/YoutubePlayerLite'))

const StyledH2 = styled.h2`
  line-height: 1.1;
`

const paragraph = 'text-white font-body text-lg md:text-xl mb-6 text-center xl:text-left'

const HomeAboutSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query HomeAboutSectionUpdateQuery {
      prismicHomePage {
        data {
          about_section_title
          about_section_content {
            richText
          }
          about_section_link_text
          about_section_youtube_video_id
        }
      }
    }
  `)

  const doc = data.prismicHomePage.data

  return (
    <div className='relative bg-red-900'>
      <div className='w-11/12 xl:max-w-screen-xl py-12 md:py-16 mx-auto'>
        <div className='flex flex-col xl:flex-row items-center'>
          <div className='w-full lg:w-9/12 xl:w-1/2 flex flex-col md:items-center xl:items-start justify-center pr-0 xl:pr-10'>
            <StyledH2 className='font-display text-4xl md:text-5xl text-center xl:text-left text-white mb-6 tracking-wider'>{doc.about_section_title}</StyledH2>

            <PrismicRichText
              field={doc.about_section_content.richText}
              components={{
                paragraph: ({ children }) => <p className={paragraph}>{children}</p>
              }}
            />

            <Link to='/about'>
              <button className="hidden xl:block bg-red-700 hover:bg-red-800 font-display text-white text-lg uppercase font-bold mt-4 py-3 px-6 mb-6 md:mb-0 rounded tracking-widest">
                {doc.about_section_link_text}
              </button>
            </Link>
          </div>

          <div className='w-full lg:w-1/2 mt-4 z-20'>
            <YoutubePlayerLite youtubeID={doc.about_section_youtube_video_id} />
          </div>

          <div className="xl:hidden">
            <Link to='/about'>
              <button className="bg-red-700 hover:bg-red-800 font-display text-white text-lg uppercase font-bold mt-8 py-3 px-6 rounded tracking-widest">
                {doc.about_section_link_text}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeAboutSectionUpdate