import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { PrismicRichText } from "@prismicio/react"

const CtaSectionNew = () => {
  const data = useStaticQuery(graphql`
    query CtaSectionNewQuery {
      prismicHomePage {
        data {
          call_to_action_section_title
          call_to_action_section_content {
            richText
          }
          call_to_action_section_link_text
        }
      }
    }
  `)

  const doc = data.prismicHomePage.data

  return (
    <div className='bg-red-900 py-12 md:py-16'>
      <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto'>
        <h2 className='font-display text-4xl md:text-5xl text-white text-center leading-tight mb-4 tracking-wider'>{doc.call_to_action_section_title}</h2>

        <PrismicRichText
          field={doc.call_to_action_section_content.richText}
          components={{
            paragraph: ({ children }) => <p className='w-full md:w-2/3 mb-8 text-center text-lg md:text-xl text-white'>{children}</p>
          }}
        />
      </div>

      <div className='w-full md:w-8/12 flex justify-center mx-auto'>
        <Link to='/contact'>
          <button className="bg-red-700 hover:bg-red-800 font-display text-white text-lg uppercase font-bold mt-4 py-3 px-6 rounded tracking-widest">
            {doc.call_to_action_section_link_text}
          </button>
        </Link>
      </div>
    </div>
  )
}

export default CtaSectionNew