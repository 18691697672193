import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// import YoutubePlayerLite from '../common/YoutubePlayerLite'
import LiteYoutubeEmbed from '../common/LiteYoutubeEmbed'

const Container = 'block'
const vidTitle = 'text-gray-800 text-xl font-semibold mt-1'

const HomeVideoSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query HomeVideoSectionUpdateQuery {
      prismicVideosSection {
        data {
          video_five_id
          video_five_title
          video_four_id
          video_four_title
          video_one_id
          video_one_title
          video_six_id
          video_six_title
          video_three_id
          video_three_title
          video_two_id
          video_two_title
        }
      }
    }
  `)

  const videos = data.prismicVideosSection

  return (
    <div className='bg-white border-t-4 border-red-900'>
      <div className='w-11/12 xl:max-w-screen-xl py-12 mx-auto'>
        <div className='flex items-center justify-center'>
          <h2 className='font-display text-4xl md:text-5xl text-gray-900 text-center tracking-wider mb-6 leading-tight'>Featured Products <span className='text-red-900'>Videos</span></h2>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          {videos.data.video_one_id
            ?
              <div className={Container}>                
                <LiteYoutubeEmbed youtubeID={videos.data.video_one_id} youtubeTitle={videos.data.video_one_title} />
                <p className={vidTitle}>{videos.data.video_one_title}</p>
              </div>
            :
              null
          }

          {videos.data.video_two_id
            ?
              <div className={Container}>
                <LiteYoutubeEmbed youtubeID={videos.data.video_two_id} youtubeTitle={videos.data.video_two_title} />
                <p className={vidTitle}>{videos.data.video_two_title}</p>
              </div>
            :
              null
          }

          {videos.data.video_three_id
            ?
              <div className={Container}>
                <LiteYoutubeEmbed youtubeID={videos.data.video_three_id} youtubeTitle={videos.data.video_three_title} />
                <p className={vidTitle}>{videos.data.video_three_title}</p>
              </div>
            :
              null
          }

          {videos.data.video_four_id
            ?
              <div className={Container}>
                <LiteYoutubeEmbed youtubeID={videos.data.video_four_id} youtubeTitle={videos.data.video_four_title} />
                <p className={vidTitle}>{videos.data.video_four_title}</p>
              </div>
            :
              null
          }

          {videos.data.video_five_id
            ?
              <div className={Container}>
                <LiteYoutubeEmbed youtubeID={videos.data.video_five_id} youtubeTitle={videos.data.video_five_title} />
                <p className={vidTitle}>{videos.data.video_five_title}</p>
              </div>
            :
              null
          }

          {videos.data.video_six_id
            ?
              <div className={Container}>
                <LiteYoutubeEmbed youtubeID={videos.data.video_six_id} youtubeTitle={videos.data.video_six_title} />
                <p className={vidTitle}>{videos.data.video_six_title}</p>
              </div>
            :
              null
          }
        </div>

        <div className='flex justify-center'>
          <Link to="/videos">
            <button className="bg-red-900 hover:bg-red-800 font-display text-white text-lg uppercase font-bold mt-6 py-3 px-6 rounded tracking-widest">
              View All Videos
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HomeVideoSectionUpdate