import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../../utils/htmlSerializer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledGatsbyImage = styled(GatsbyImage)`
  grid-area: 1/1;
  object-fit: cover;
  opacity: none;
  width: 100%;
  height: 400px;

  @media (max-width: 767px) {
    height: 650px;
  }
`

const ProductLandingPageBannerSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query ProductLandingPageBannerSectionUpdateQuery {
      prismicHomePage {
        data {
          banner_text {
            richText
          }
          banner_button_text
          banner_button_link_url {
            url
          }
          banner_product_image {
            gatsbyImageData(width: 420)
            alt
          }
          banner_background {
            gatsbyImageData(width: 1880)
            alt
          }
        }
      }
      imageBg: file(relativePath: { eq: "header/hero-image-background.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 877)
        }
      }
    }
  `);

  const doc = data.prismicHomePage
  const imageData = getImage(doc.data.banner_product_image)
  const imageBg = getImage(doc.data.banner_background)
  const imageBgDefault = getImage(data.imageBg);

  return (
    <div className="w-full grid relative" style={{ position: 'relative' }}>
      <StyledGatsbyImage
        alt=""
        image={imageBg ? imageBg : imageBgDefault}
        formats={['auto', 'webp', 'avif']}
        width={1366}
      />
      
      <div
        style={{
          gridArea: '1/1',
          position: 'relative',
          placeItems: 'center',
          display: 'grid',
          zIndex: 2,
        }}
      >
        <div className='w-11/12 xl:max-w-screen-xl flex flex-col md:flex-row items-center mt-6 md:mt-0'>
          <div className='banner-content w-full md:w-8/12'>
            <RichText
              render={doc.data.banner_text.richText}
              htmlSerializer={htmlSerializer}
            />

            <div className="flex items-center md:items-start justify-center md:justify-start">
              <a href={doc.data.banner_button_link_url.url}>
                <button className="bg-red-700 hover:bg-red-800 font-display text-white text-lg uppercase font-bold mt-4 py-3 px-6 mb-6 md:mb-0 rounded tracking-widest">
                  {doc.data.banner_button_text}
                </button>
              </a>
            </div>
          </div>

          <div className='w-full md:w-4/12 flex items-center md:items-end justify-center md:justify-end'>
            <GatsbyImage
              image={imageData}
              alt={imageData.alt}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLandingPageBannerSectionUpdate;