import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getSrc } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import loadable from '@loadable/component';
import styled from "styled-components"

const HomeHeroTextUpdate = loadable(() => import('./HomeHeroTextUpdate'));

const HomeHeroWithImageBackground = () => {
  const data = useStaticQuery(graphql`
    query HomeHeroWithImageBackgroundQuery {      
      imageBg: file(relativePath: { eq: "header/hero-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1366
            formats: [WEBP]
          )
        }
      }
    }
  `)

  const imageBg = getSrc(data.imageBg)

  const StyledDiv = styled.div`
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100vh;
    background-image: url(${imageBg}); /* The image used */
    background-color: #000000; /* Used if the image is unavailable */
    /* height: 500px;  You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    @media (min-width: 1280px) {
      display: flex;
    }
  `

  return (
    <div>
      <Helmet>
        <link rel="preload" as="image" href={imageBg} />
      </Helmet>

      <StyledDiv>
        <HomeHeroTextUpdate />
      </StyledDiv>          
    </div>
  )
}

export default HomeHeroWithImageBackground