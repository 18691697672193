import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const StyledDiv = styled.div`
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
`

const Container = 'relative bg-white cursor-pointer flex flex-col p-4 rounded shadow-md hover:shadow-xl border border-gray-200 hover:border-red-800 text-red-900 hover:text-red-800 overflow-hidden'
const titleWrapper = 'bg-gray-100 p-2 mt-2 rounded'
const Title = 'font-display text-2xl uppercase text-center tracking-wider'
const productImg = 'w-64 mx-auto'

const HomeProductsSectionNew = () => (
  <div className='bg-gray-200 border-t border-gray-200 pt-12 md:pt-10 pb-2'>
    <div className='w-11/12 xl:max-w-screen-xl flex items-center justify-center mx-auto'>
      <h2 className='font-display text-4xl md:text-5xl text-gray-900 text-center tracking-wider mb-6 leading-tight'>Product <span className='text-red-900'>Categories</span></h2>
    </div>

    <div className='w-11/12 xl:max-w-screen-xl grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 mx-auto mb-12'>
      <Link to='/mortise-locks'>
        <StyledDiv className={Container}>
          <StaticImage
            src="../../images/products/mortise-locks/ML90-M-Series-Schlage-L9000.png"
            width={500}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="mortise lock"
            className={productImg}
          />
          <div className={titleWrapper}>
            <h2 className={Title}>Mortise Locks</h2>
          </div>
        </StyledDiv>
      </Link>

      <Link to='/cylindrical-locks'>
        <StyledDiv className={Container}>
          <StaticImage
            src="../../images/products/cylindrical-locks/CL180-Cylindrical-Lock.png"
            width={500}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="cylindrical lock"
            className={productImg}
          />
          <div className={titleWrapper}>
            <h2 className={Title}>Cylindrical Locks</h2>
          </div>
        </StyledDiv>
      </Link>

      <Link to='/exit-device-kits'>
        <StyledDiv className={Container}>
          <StaticImage
            src="../../images/products/exit-device-kits/MLRK1-YAL6-72506.png"
            width={500}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="exit device kit"
            className={productImg}
          />
          <div className={titleWrapper}>
            <h2 className={Title}>Exit Device Kits</h2>
          </div>
        </StyledDiv>
      </Link>

      <Link to='/exit-devices'>
        <StyledDiv className={Container}>
          <StaticImage
            src="../../images/products/exit-devices/PD15-16-Exit-Device-Series.png"
            width={500}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="exit device"
            className={productImg}
          />
          <div className={titleWrapper}>
            <h2 className={Title}>Exit Devices</h2>
          </div>
        </StyledDiv>
      </Link>

      <Link to='/exit-trims'>
        <StyledDiv className={Container}>
          <StaticImage
            src="../../images/products/exit-trims/ET15-16-Exit-Trim.png"
            width={500}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="exit trims"
            className={productImg}
          />
          <div className={titleWrapper}>
            <h2 className={Title}>Exit Trims</h2>
          </div>
        </StyledDiv>
      </Link>

      <Link to='/power-supplies'>
        <StyledDiv className={Container}>
          <StaticImage
            src="../../images/products/power-supplies/PS210-Power-Supply.png"
            width={500}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="power supplies"
            className={productImg}
          />
          <div className={titleWrapper}>
            <h2 className={Title}>Power Supplies</h2>
          </div>
        </StyledDiv>
      </Link>

      <Link to='/power-transfers'>
        <StyledDiv className={Container}>
          <StaticImage
            src="../../images/products/power-transfers/ETH-5-Knuckle.png"
            width={500}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="power transfers"
            className={productImg}
          />
          <div className={titleWrapper}>
            <h2 className={Title}>Power Transfers</h2>
          </div>
        </StyledDiv>
      </Link>

      <Link to='/accessories'>
        <StyledDiv className={Container}>
          <StaticImage
            src="../../images/products/accessories/Accessories.jpg"
            width={500}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="accessories"
            className={productImg}
          />
          <div className={titleWrapper}>
            <h2 className={Title}>Accessories</h2>
          </div>
        </StyledDiv>
      </Link>

      <Link to='/command-packages'>
        <StyledDiv className={Container}>
          <StaticImage
            src="../../images/products/command-packages/universal-bathroom-privacy-kit.png"
            width={500}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Command Packages"
            className={productImg}
          />
          <div className={titleWrapper}>
            <h2 className={Title}>Command Packages</h2>
          </div>
        </StyledDiv>
      </Link>

      <Link to='/low-energy-operator'>
        <StyledDiv className={Container}>
          <StaticImage
            src="../../images/products/low-energy-operator/cat-hdo-1000.png"
            width={500}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Low Energy Operator"
            className={productImg}
          />
          <div className={titleWrapper}>
            <h2 className={Title}>Low Energy Operator</h2>
          </div>
        </StyledDiv>
      </Link>
    </div>
  </div>
)

export default HomeProductsSectionNew