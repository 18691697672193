import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import ProductCard from '../common/ProductCard'

const slugify = require('@sindresorhus/slugify')

const FeaturedProductsSection = () => {
  const data = useStaticQuery(graphql`
    query FeaturedProductsQuery {
      allPrismicProduct {
        edges {
          node {
            uid
            data {
              featured_product
              product_name
              product_brand
              product_type
              category
              motor_driven
              product_image {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  `)

  const allProducts = data.allPrismicProduct.edges

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  }

  return (
    <>
      <div className='bg-red-900 flex items-center justify-center px-4 pt-8 pb-6 border-t border-red-100 shadow'>
        <h2 className='font-display text-4xl md:text-5xl text-white text-center tracking-wider leading-tight'>Featured Products</h2>
      </div>

      <div className='bg-red-900 pb-12 px-4'>
        <div className='w-11/12 xl:max-w-screen-xl mx-auto'>
          <Slider {...settings}>
            {allProducts.map( allProduct => {
              const imageData = getImage(allProduct.node.data.product_image)
              return (
                allProduct.node.data.featured_product
                ?
                <ProductCard
                  linkTo={allProduct.node.data.category && allProduct.node.data.product_name ? `/${slugify(allProduct.node.data.category)}/${allProduct.node.uid}` : '/' }
                  imageData={imageData}
                  altText={allProduct.node.data.product_name ? `Command Access ${allProduct.node.data.product_name}` : ''}
                  prodName={allProduct.node.data.product_name}
                  prodSub1={allProduct.node.data.product_brand}
                  prodSub2={allProduct.node.data.product_type}
                  withBadge={allProduct.node.data.motor_driven ? true : false}
                  isFeatured
                />
                :
                null
              )
            })}
          </Slider>
        </div>
      </div>
    </>
  )
}

export default FeaturedProductsSection