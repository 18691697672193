import React from 'react'
import PropTypes from 'prop-types'
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const LiteYoutubeEmbed = ({ youtubeID, youtubeTitle }) => (
  <div className='shadow-lg'>
    <LiteYouTubeEmbed 
        id={youtubeID ? youtubeID : '20EwgqP00BE'}
        title={youtubeTitle ? youtubeTitle : 'Command Access Video'}
        poster="hqdefault"
        playerClass="lty-playbtn"
        webp
    />
  </div>
)

export default LiteYoutubeEmbed

LiteYoutubeEmbed.propTypes = {
  youtubeID: PropTypes.string,
  youtubeTitle: PropTypes.string,
}